import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'

const FastLocalizer = () => {
    return (
        <Container>
            <Row style={{width: '100%', justifyContent: 'center', marginTop: '3%'}}>
                <Image
                    style={{
                        width: '600px',
                    }}
                    src={require('../assets/fastlocalizer-logo.png')}
                />
            </Row>
            <Row>
                <div style={{ marginTop: 25 }}>
                    <p>
                        Fast Localizer simplifies your project's localization. Store your content in a CSV or JSON file, and Fast Localizer imports it into a key-based lookup system. With ChatGPT and Google Translate API support, translate your file to almost any language before importing. Pass in a key, and get the translated text instantly. Focus on creating—Fast Localizer handles the rest.
                    </p>
                    <h3>System Components</h3>
                    <h5><u>Fast Localizer Subsystem</u></h5>
                    <p/>
                    <p><b>Lightning-Fast Lookup Subsystem</b>: Retrieve translated text on the fly with our optimized lookup system, ensuring smooth gameplay across all supported languages.</p>
                    <p><b>Runtime Language Switching</b>: Give your players the flexibility to switch languages on the fly during gameplay. Fast Localizer allows for easy language changes at runtime, enhancing user experience and accessibility.</p>
                    <p><b>Text & Text Render Wrapper Widgets</b>: These widgets are specifically designed to work directly with the Fast Localizer Subsystem, making the translation of text elements within your project straightforward and easy.</p>
                    <h5><u>Fast Localizer Importer</u></h5>
                    <p/>
                    <p><b>Editor Utility Widget</b>: This robust tool allows you to quickly import your CSV or JSON language dictionaries directly into the Fast Localizer Subsystem.</p>
                    <p><b>Instant Translation:</b> Easily translate your files into multiple languages with just the push of a button. The importer supports various translation services, including:</p>
                    <ul>
                        <li><b>ChatGPT</b></li>
                        <li><b>Google Translate API</b></li>
                        <li><b>More to come soon!</b></li>
                    </ul>
                    <p>Experience a new level of efficiency and speed in your translation process.</p>
                    <p>
                        <a href='https://www.unrealengine.com/marketplace/en-US/product/fast-localizer'>Fast Localizer - Unreal Engine Marketplace</a>
                    </p>
                </div>
            </Row>
            <Row style={{width: '100%', marginTop: '1%', flexDirection: 'row',  display: 'inline-block'}}>
                <h3>Related Files</h3>
                <a href='https://www.herocode.us/fastlocalizer/fastlocalizer.pdf'>Fast Localizer Documentation</a>
                <a href='https://www.herocode.us/fastlocalizer/fastlocalizer-faq.pdf'>Frequently Asked Questions</a>
                <a href='https://www.herocode.us/fastlocalizer/FastLocalizerSampleFiles.zip'>Sample Localizer Files</a>
            </Row>
        </Container>
    )
}

export default FastLocalizer